import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { useEffect } from "react";
import IP from '../services/S IP C.png'
import Satellite from '../services/Satellite.png'
import Fiber from '../services/Fibre.jpg'
import P2P from '../services/P2P.png'
import Service from '../services/ser.png'
import DV from '../services/D&V Con.png'
import Repair from '../services/Rapair switch.jpg'
import Refuel from '../services/Refuel.png'
import Teleporting from '../services/Teleporting.png'

function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <Navbar />
      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="lg:w-5/5 mx-auto flex flex-wrap">
            <img
              className="fixed-image lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
              loading="lazy"
              alt="Satellite IP Connectivity"
              src={IP}
            />
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h1 className="text-gray-900 text-4xl title-font font-bold mb-1">
                Satellite IP Connectivity
              </h1>
              <p className="leading-relaxed">
                For businesses operating in remote locations or requiring backup
                connectivity, we provide high-speed satellite IP services.
                <p className="mt-2">Our services include:</p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> 4 Mbps IP
                  Connectivity (Unlimited Download)
                </p>
                <p className="ml-3">High-speed, reliable internet access.</p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> VoIP & Secure
                  Communications
                </p>
                <p className="ml-3">
                  Enabling remote offices to maintain contact with HQs.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Redundant Network
                  Solutions
                </p>
                <p className="ml-3">
                  Ensuring uninterrupted operations during fiber network
                  failures.
                </p>
                <p className="ml-3 mt-2">
                  Our satellite services have been used for government biometric
                  systems, enhancing data security and connectivity.
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>

      <hr className="mt-9" />

      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="lg:w-5/5 mx-auto flex flex-wrap">
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h1 className="text-gray-900 text-4xl title-font font-bold mb-1">
                Satellite Bandwidth for Video Distribution & Video Contribution
              </h1>
              <p className="leading-relaxed">
                SoftBridge provides satellite bandwidth solutions for
                high-quality video transmission.
                <p className="mt-2">Our services cater to:</p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Live News & Sports
                  Broadcasting
                </p>
                <p className="ml-3">
                  Low-latency satellite uplinking for media companies.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Corporate &
                  Government Streaming
                </p>
                <p className="ml-3">
                  Secure and reliable video distribution for official
                  communications.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Disaster Recovery
                  & Emergency Broadcasting
                </p>
                <p className="ml-3">
                  Ensuring uninterrupted connectivity during crises.
                </p>
                <p className="ml-3 mt-2">
                  With global coverage and flexible bandwidth allocation, we
                  ensure that businesses receive high-quality video feeds with
                  minimal latency.
                </p>
              </p>
            </div>
            <img
              className="fixed-image lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
              loading="lazy"
              alt="Satellite Bandwidth for Video Distribution & Video Contribution"
              src={Satellite}
              style={{ height: "488px" }}
            />
          </div>
        </div>
      </section>

      <hr className="mt-9" />

      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="lg:w-5/5 mx-auto flex flex-wrap">
            <img
              className="fixed-image lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
              loading="lazy"
              alt="Fiber Deployment, FTTH, GPON"
              src={Fiber}
            />
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h1 className="text-gray-900 text-4xl title-font font-bold mb-1">
                Fiber Deployment, FTTH, GPON
              </h1>
              <p className="leading-relaxed">
                SoftBridge specializes in fiber network deployment for telecom
                operators and ISPs.
                <p className="mt-2">Our solutions include:</p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> FTTH
                  (Fiber-to-the-Home)
                </p>
                <p className="ml-3">
                  Providing high-speed broadband for residential and business
                  users.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> GPON (Gigabit
                  Passive Optical Network)
                </p>
                <p className="ml-3">
                  Enhancing broadband efficiency with scalable solutions.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Long-Haul Fiber
                  Networks
                </p>
                <p className="ml-3">
                  Enabling nationwide fiber optic connectivity.
                </p>
                <p className="ml-3 mt-2">
                  These services help expand high-speed internet access and
                  improve network performance for end-users.
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>

      <hr className="mt-9" />

      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="lg:w-5/5 mx-auto flex flex-wrap">
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h1 className="text-gray-900 text-4xl title-font font-bold mb-1">
                Last Mile P2MP Access Networks
              </h1>
              <p className="leading-relaxed">
                To improve broadband connectivity and telecom coverage,
                SoftBridge deploys Point-to-Multipoint (P2MP) wireless access
                networks for telecom operators and enterprises.
                <p className="mt-2">These include:</p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Expanded Coverage
                </p>
                <p className="ml-3">
                  Connecting remote locations where fiber deployment is
                  challenging.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> High-Speed
                  Wireless Connectivity
                </p>
                <p className="ml-3">
                  Providing seamless internet access to end users.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Scalability & Cost
                  Efficiency
                </p>
                <p className="ml-3">
                  Reducing the cost of network expansion for telecom providers.
                </p>
                <p className="ml-3 mt-2">
                  We have installed P2MP systems for PTCL, enabling corporate
                  customers to connect to HQs more efficiently.
                </p>
              </p>
            </div>
            <img
              className="fixed-image lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
              loading="lazy"
              alt="Last Mile P2MP Access Networks"
              src={P2P}
            />
          </div>
        </div>
      </section>

      <hr className="mt-9" />

      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="lg:w-5/5 mx-auto flex flex-wrap">
            <img
              className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
              alt="IT & Telecom Consultancy"
              src="https://media.licdn.com/dms/image/v2/D4D12AQFzYytYlfFe6Q/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1653558017656?e=1746057600&v=beta&t=zgXTwMBEdUJv4qhXi9iQx5cObtzr-E1g5QkIy-ICLUg"
            />
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h1 className="text-gray-900 text-4xl title-font font-bold mb-1">
                IT & Telecom Consultancy
              </h1>
              <p className="leading-relaxed">
                We provide expert consulting services for telecom and IT
                companies to optimize network infrastructure, enhance
                cybersecurity, and integrate emerging technologies.
                <p className="mt-2">Our consultancy covers:</p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Network
                  Infrastructure Planning
                </p>
                <p className="ml-3">
                  Designing robust telecom networks (fiber, wireless, and
                  satellite) for high-speed connectivity.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Cybersecurity
                  Solutions
                </p>
                <p className="ml-3">
                  Implementing data protection, firewall configurations, and
                  risk management strategies.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Cloud Computing &
                  Data Center Setup
                </p>
                <p className="ml-3">
                  Helping businesses migrate to the cloud for enhanced storage
                  and scalability.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Regulatory
                  Compliance & Licensing
                </p>
                <p className="ml-3">
                  Ensuring compliance with local and international telecom
                  regulations.
                </p>
                <p className="ml-3 mt-2">
                  Our expertise allows businesses to implement the latest
                  technologies while reducing risks and operational costs.
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>

      <hr className="mt-9" />

      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="lg:w-5/5 mx-auto flex flex-wrap">
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h1 className="text-gray-900 text-4xl title-font font-bold mb-1">
                High-End Software Applications
              </h1>
              <p className="leading-relaxed">
                SoftBridge develops custom enterprise-grade software solutions
                that enhance productivity and automate operations.
                <p className="mt-2">Our offerings include:</p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Enterprise
                  Resource Planning (ERP)
                </p>
                <p className="ml-3">
                  Integrated software for managing finance, HR, supply chain,
                  and customer relations.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> E-Commerce
                  Platforms
                </p>
                <p className="ml-3">
                  Scalable online stores with secure payment gateways.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Data Analytics &
                  AI Solutions
                </p>
                <p className="ml-3">
                  Predictive analytics, AI-driven automation, and real-time
                  business intelligence tools.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Custom Web &
                  Mobile Applications
                </p>
                <p className="ml-3">
                  Tailor-made apps for business operations and customer
                  engagement.
                </p>
                <p className="ml-3 mt-2">
                  We deployed a USD 1M software system for a government
                  organization, automating financial audits and increasing
                  operational efficiency from 30% to 99.99%.
                </p>
              </p>
            </div>
            <img
              className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
              alt="High-End Software Applications"
              src="https://www.techtiera.com/wp-content/uploads/2024/12/custom-software-development-services.png"
            />
          </div>
        </div>
      </section>

      <hr className="mt-9" />

      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="lg:w-5/5 mx-auto flex flex-wrap">
            <img
              className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
              alt="Business Development"
              src="https://media.licdn.com/dms/image/v2/D4D12AQHSEA9LKGeXkg/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1678622790030?e=1746057600&v=beta&t=8n3EWjuFx90V8pn-tx8X2XwRnybAZnKykpKPenArSqQ"
            />
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h1 className="text-gray-900 text-4xl title-font font-bold mb-1">
                Business Development
              </h1>
              <p className="leading-relaxed">
                SoftBridge provides strategic business development services to
                help companies grow, optimize operations, and expand their
                market reach.
                <p className="mt-2">Our approach includes:</p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Market Research &
                  Analysis
                </p>
                <p className="ml-3">
                  Understanding customer demands, competitors, and emerging
                  trends.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Strategic
                  Partnerships{" "}
                </p>
                <p className="ml-3">
                  Establishing collaborations with telecom operators, technology
                  firms, and government entities.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Investment &
                  Funding Support{" "}
                </p>
                <p className="ml-3">
                  Assisting companies in securing funding for technology
                  infrastructure and expansion.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Operational
                  Efficiency Enhancement{" "}
                </p>
                <p className="ml-3">
                  Implementing automation and digital transformation strategies
                  to streamline workflows.
                </p>
                <p className="ml-3 mt-2">
                  This ensures that businesses stay competitive and maximize
                  profitability in the evolving IT and telecom landscape.
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>

      <hr className="mt-9" />

      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="lg:w-5/5 mx-auto flex flex-wrap">
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h1 className="text-gray-900 text-4xl title-font font-bold mb-1">
                Fully Managed Automated Systems
              </h1>
              <p className="leading-relaxed">
                We offer end-to-end automation solutions for telecom providers,
                enterprises, and government agencies.
                <p className="mt-2">Our automation services include:</p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> AI-Powered
                  Workflow Automation
                </p>
                <p className="ml-3">
                  Reducing manual tasks and improving response times.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Remote Monitoring
                  & Control Systems
                </p>
                <p className="ml-3">
                  Allowing real-time tracking of operations and network
                  performance.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> IoT-Enabled Smart
                  Infrastructure
                </p>
                <p className="ml-3">
                  Connecting devices and sensors for automated data collection
                  and predictive maintenance.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Cybersecurity
                  Automation
                </p>
                <p className="ml-3">
                  AI-driven threat detection and prevention.
                </p>
                <p className="ml-3 mt-2">
                  By implementing automated systems, businesses can enhance
                  efficiency, reduce costs, and improve service delivery.
                </p>
              </p>
            </div>
            <img
              className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
              alt="Fully Managed Automated Systems"
              src="https://www.simplilearn.com/ice9/free_resources_article_thumb/Advantages_and_Disadvantages_of_Automation.jpg"
            />
          </div>
        </div>
      </section>

      <hr className="mt-9" />

      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="lg:w-5/5 mx-auto flex flex-wrap">
            <img
              className="fixed-image lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
              loading="lazy"
              alt="Service Delivery Platforms"
              src={Service}
            />
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h1 className="text-gray-900 text-4xl title-font font-bold mb-1">
                Service Delivery Platforms
              </h1>
              <p className="leading-relaxed">
                SoftBridge provides service delivery platforms (SDPs) that
                enable businesses to launch and manage digital services.
                <p className="mt-2">These include:</p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> SMS Campaign
                  Management
                </p>
                <p className="ml-3">
                  Bulk messaging solutions for promotions, alerts, and
                  notifications.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Voice Broadcasting
                </p>
                <p className="ml-3">
                  Automated voice calls for customer engagement and government
                  alerts.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Hosted Email
                  Services
                </p>
                <p className="ml-3">
                  Secure email hosting for enterprises and government agencies.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Web Hosting &
                  Cloud Storage
                </p>
                <p className="ml-3">
                  Reliable infrastructure for websites and data storage.
                </p>
                <p className="ml-3 mt-2">
                  These platforms allow businesses to engage with customers
                  efficiently and improve operational communication.
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>

      <hr className="mt-9" />

      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="lg:w-5/5 mx-auto flex flex-wrap">
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h1 className="text-gray-900 text-4xl title-font font-bold mb-1">
                Data & Voice Solutions
              </h1>
              <p className="leading-relaxed">
                We offer comprehensive communication solutions for enterprises
                and telecom operators.
                <p className="mt-2">This includes:</p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> VoIP & SIP
                  Trunking Services
                </p>
                <p className="ml-3">
                  Cost-effective voice solutions for businesses.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Secure Data
                  Encryption & VPNs
                </p>
                <p className="ml-3">Enhancing corporate cybersecurity.</p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Global
                  Connectivity for Businesses
                </p>
                <p className="ml-3">
                  Enabling seamless communication across multiple locations.
                </p>
                <p className="ml-3 mt-2">
                  This helps companies enhance internal and external
                  communication with secure, high-quality voice and data
                  services.
                </p>
              </p>
            </div>
            <img
              className="fixed-image lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
              loading="lazy"
              alt="Data & Voice Solutions"
              src={DV}
            />
          </div>
        </div>
      </section>

      <hr className="mt-9" />

      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="lg:w-5/5 mx-auto flex flex-wrap">
            <img
              className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
              alt="Repair Services for Switching, Transmission & Power Systems"
              src={Repair}
              style={{ height: "600px" }}
            />
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h1 className="text-gray-900 text-4xl title-font font-bold mb-1">
                Repair Services for Switching, Transmission & Power Systems
              </h1>
              <p className="leading-relaxed">
                SoftBridge provides expert repair and maintenance services for
                telecom infrastructure.
                <p className="mt-2">It includes:</p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Switching &
                  Routing Equipment Repairs
                </p>
                <p className="ml-3">Ensuring network uptime and reliability.</p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Transmission
                  System Optimization
                </p>
                <p className="ml-3">
                  Upgrading and troubleshooting fiber, microwave, and satellite
                  links.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Power Systems &
                  Backup Solutions
                </p>
                <p className="ml-3">
                  Maintaining UPS and battery backup systems.
                </p>
                <p className="ml-3 mt-2">
                  Our 24/7 support and preventive maintenance programs help
                  businesses avoid costly network downtime.
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>

      <hr className="mt-9" />

      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="lg:w-5/5 mx-auto flex flex-wrap">
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h1 className="text-gray-900 text-4xl title-font font-bold mb-1">
                Re-Fueling Services for Telecom & Non-Telecom Clients
              </h1>
              <p className="leading-relaxed">
                We provide fuel logistics for power backup systems in remote
                areas, ensuring continuous operations.
                <p className="mt-2">It cater:</p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Telecom Towers &
                  Data Centers
                </p>
                <p className="ml-3">
                  Preventing downtime due to power failures.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Industrial &
                  Government Facilities
                </p>
                <p className="ml-3">
                  Reliable fuel supply for critical infrastructure.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Emergency &
                  Disaster Management Services
                </p>
                <p className="ml-3">
                  Ensuring power continuity in crisis zones.
                </p>
                <p className="ml-3 mt-2">
                  This helps businesses maintain uninterrupted services, even in
                  off-grid locations.
                </p>
              </p>
            </div>
            <img
              className="fixed-image lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
              loading="lazy"
              alt="Re-Fueling Services for Telecom & Non-Telecom Clients"
              src={Refuel}
            />
          </div>
        </div>
      </section>

      <hr className="mt-9" />

      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="lg:w-5/5 mx-auto flex flex-wrap">
            <img
              className="fixed-image lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
              loading="lazy"
              alt="Teleporting"
              src={Teleporting}
            />
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h1 className="text-gray-900 text-4xl title-font font-bold mb-1">
                Teleporting
              </h1>
              <p className="leading-relaxed">
                SoftBridge provides teleport services for uplink and downlink
                operations, ensuring seamless satellite communication
                <p className="mt-2">These include:</p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Broadcasters &
                  Telecom Operators
                </p>
                <p className="ml-3">
                  Secure and high-speed uplinking for live events and data
                  transmission.
                </p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Government &
                  Military Networks
                </p>
                <p className="ml-3">Secure intercontinental communication.</p>
                <p className="font-bold">
                  <i className="fa-solid fa-caret-right"></i> Multi-Orbit
                  Connectivity
                </p>
                <p className="ml-3">
                  Enabling businesses to access geostationary and
                  non-geostationary satellites.
                </p>
                <p className="ml-3 mt-2">
                  This ensures seamless connectivity for international data
                  exchange and enterprise operations.
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>

      <hr className="mt-9" />

      <Footer />
    </>
  );
}

export default Services;
