import React, { useEffect, useState } from "react";
import CBO from "../CBO.jpg";
import SSM from "../SSM.png";
import GM from "../GM.jpg";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

const TeamMember = ({ image, name, role, description }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="p-4 lg:w-1/2 w-full">
      <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-left sm:text-left">
        {isLoading && (
          <div className="flex-shrink-0 rounded-lg w-48 h-48 bg-gray-200 animate-pulse"></div>
        )}
        <img
          alt={name}
          className={`flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4 ${
            isLoading ? "hidden" : ""
          }`}
          src={image}
          onLoad={() => setIsLoading(false)}
        />
        <div className="flex-grow sm:pl-8">
          <h2 className="title-font font-medium text-lg text-gray-900">
            {name}
          </h2>
          <h3 className="text-gray-500 mb-3">{role}</h3>
          <p className="mb-4">{description}</p>
        </div>
      </div>
    </div>
  );
};

const Team = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component is mounted
  }, []);

  const teamMembers = [
    {
      image:
        "https://thumbs.dreamstime.com/b/user-woman-icon-lady-s-profile-female-web-sign-flat-art-object-black-white-silhouette-girl-business-suit-avatar-picture-173159996.jpg",
      name: "Naheed Akhtar",
      role: "CEO",
      description:
        "Provides visionary leadership, drives business strategy, fosters innovation, and ensures sustainable growth and success of the organization.",
    },
    {
      image: CBO,
      name: "Zahid Mahmood",
      role: "Chief Business Officer",
      description:
        "Provides strategic leadership, supports all members of the organization, and drives revenue growth while ensuring sustainable business success.",
    },
    {
      image: GM,
      name: "Sajjad H Salik",
      role: "GM Corporate Sales",
      description:
        "Leads corporate sales strategy, builds strong client relationships, and drives business growth through strategic partnerships and market expansion.",
    },
    {
      image: SSM,
      name: "Abdul Wahid Bhatti",
      role: "Senior Manager Special Projects",
      description:
        "Manages special and general projects, including government initiatives, while playing a key role in driving import services and overall business growth.",
    },
  ];

  return (
    <>
      <Navbar />
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-14 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="text-5xl font-bold mb-4 text-gray-900">OUR TEAM</h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              Our experienced team brings a wealth of expertise in delivering
              innovative solutions and exceptional services tailored to meet
              diverse business needs.
            </p>
          </div>
          <hr className="mb-5 -mt-10" />
          <div className="flex flex-wrap -m-4">
            {teamMembers.map((member, index) => (
              <TeamMember
                key={index}
                image={member.image}
                name={member.name}
                role={member.role}
                description={member.description}
              />
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Team;
