import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    organization: "",
    subject: "",
    message: "",
  });

  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");

    try {
      const response = await fetch("http://localhost:5000/api/send-email", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      // Check if response is successful
      if (!response.ok) {
        throw new Error("Server error. Try again later.");
      }

      await response.json();

      toast.success("Message sent successfully!", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setFormData({
        name: "",
        email: "",
        phone: "",
        organization: "",
        subject: "",
        message: "",
      });
      setStatus("");
    } catch (error) {
      console.error("Error sending message:", error);
      setStatus("Failed to send message!");
      toast.error("Failed to send message. Please try again.", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <>
      {/* Toast Notification Setup */}
      <ToastContainer />
      <Navbar />
      <section className="text-gray-600 body-font relative">
        <div className="container px-5 py-14 mx-auto flex sm:flex-nowrap flex-wrap">
          <div
            className="lg:w-2/3 md:w-1/2 bg-gray-300 shadow rounded-lg sm:mr-10 p-10 flex items-end justify-start relative"
            style={{ maxWidth: "900px" }}
          >
            <iframe
              width="100%"
              height="100%"
              className="map absolute inset-0"
              frameBorder="0"
              title="map"
              marginHeight="0"
              marginWidth="0"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3319.6166183554814!2d73.0560167!3d33.7118859!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbf80ea2ad6b5%3A0x969a03a1b5cc0bf!2sMoscow%20Plaza!5e0!3m2!1sen!2s!4v1706877420000!5m2!1sen!2s"
            ></iframe>
          </div>
          <form
            onSubmit={handleSubmit}
            className="lg:w-1/3 md:w-1/2 shadow rounded bg-white flex flex-col md:ml-auto w-full md:py-6 mt-8 md:mt-0"
          >
            <div className="ml-5 mr-5">
              <h2 className="text-gray-900 text-2xl mb-1 font-bold title-font">
                Contact Us
              </h2>
              <p className="leading-relaxed mb-2 text-gray-600">
                Have any questions or inquiries? Fill out the form below, and
                we'll get back to you as soon as possible by Email.
              </p>
              <div className="relative mb-3">
                <label
                  htmlFor="name"
                  className="leading-7 text-sm font-bold text-gray-600"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required
                />
              </div>
              <div className="relative mb-3">
                <label
                  htmlFor="email"
                  className="leading-7 text-sm font-bold text-gray-600"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required
                />
              </div>
              <div className="relative mb-3">
                <label
                  htmlFor="phone"
                  className="leading-7 text-sm font-bold text-gray-600"
                >
                  Phone Number
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="Your Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required
                />
              </div>
              <div className="relative mb-3">
                <label
                  htmlFor="organization"
                  className="leading-7 text-sm font-bold text-gray-600"
                >
                  Organization
                </label>
                <input
                  type="text"
                  id="organization"
                  name="organization"
                  placeholder="Your Organization"
                  value={formData.organization}
                  onChange={handleChange}
                  className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required
                />
              </div>
              <div className="relative mb-3">
                <label
                  htmlFor="subject"
                  className="leading-7 text-sm font-bold text-gray-600"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  placeholder="Subject"
                  value={formData.subject}
                  onChange={handleChange}
                  className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required
                />
              </div>
              <div className="relative mb-3">
                <label
                  htmlFor="message"
                  className="leading-7 text-sm font-bold text-gray-600"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="text-white bg-blue-500 border-0 py-2 w-full px-6 focus:outline-none hover:bg-blue-600 rounded text-lg"
              >
                {status === "Sending..." ? "Sending..." : "Send Message"}
              </button>
              <p className="text-xs text-gray-500 mt-2 mb-1">
                We'll get back to you within 1-2 business days.
              </p>
            </div>
          </form>
        </div>
        <div className="shadow rounded bg-white p-10 flex flex-wrap py-6 mb-4 ml-12 mr-12">
          <div className="lg:w-1/2 px-6">
            <h2 className="title-font font-bold text-gray-900 tracking-widest text-sm">
              ADDRESS
            </h2>
            <p className="mt-1">
              Office#3, First Floor, 64-West Moscow Plaza, Block-H, Blue Area,
              Islamabad, Pakistan.
            </p>
            <h2 className="title-font font-bold text-gray-900 tracking-widest text-sm mt-4">
              EMAIL
            </h2>
            <p className="text-blue-500 leading-relaxed">
              info@softbridge.com.pk
            </p>
          </div>
          <div className="lg:w-1/2 px-6">
            <h2 className="title-font font-bold text-gray-900 tracking-widest text-sm">
              Telephone
            </h2>
            <p className="mt-1">+92 51 2348599</p>

            <h2 className="title-font font-bold text-gray-900 tracking-widest text-sm mt-4">
              Fax
            </h2>
            <p className="leading-relaxed">+92 51 2348600</p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Contact;
