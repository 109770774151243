import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import "../App.css";
import {
  FaSatellite,
  FaCloud,
  FaBroadcastTower,
  FaBusinessTime,
  FaNetworkWired,
  FaGlobe,
} from "react-icons/fa";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // List of services with icons
  const services = [
    {
      icon: <FaBusinessTime className="text-6xl text-[#3674B5]" />,
      title: "Business Development",
      description:
        "Strategic growth solutions for telecom and enterprise clients.",
    },
    {
      icon: <FaCloud className="text-6xl text-[#5DB998]" />,
      title: "High-End Software Applications",
      description: "Developing advanced software for seamless automation.",
    },
    {
      icon: <FaBroadcastTower className="text-6xl text-[#3674B5]" />,
      title: "Satellite Bandwidth Solutions",
      description: "Reliable video distribution and contribution solutions.",
    },
    {
      icon: <FaSatellite className="text-6xl text-[#5DB998]" />,
      title: "Satellite IP Connectivity",
      description: "Global internet and communication services via satellite.",
    },
    {
      icon: <FaNetworkWired className="text-6xl text-[#3674B5]" />,
      title: "Fiber Deployment & GPON",
      description: "High-speed fiber optic network solutions for businesses.",
    },
    {
      icon: <FaGlobe className="text-6xl text-[#5DB998]" />,
      title: "Service Delivery Platforms",
      description: "End-to-end service management for telecom and IT networks.",
    },
  ];

  // Major Achievements
  const achievements = [
    {
      title: "HEC Project with Zong",
      description:
        "Soft Bridge helped Zong secure a PKR 600 million project for distributing 100,000 3G dongles under the PM Laptop Scheme Phase-II.",
      year: "2024",
    },
    {
      title: "SCO Connectivity Project",
      description:
        "Facilitated the acquisition of long-haul connectivity, ensuring cost-effectiveness and revenue generation for SCO.",
      year: "2023",
    },
    {
      title: "100,000 Mobile Broadband Devices",
      description:
        "Signed a landmark agreement for the provision of mobile broadband devices to Zong.",
      year: "2022",
    },
    {
      title: "Government Automation Project",
      description:
        "Delivered a USD 1.0M automation project to a government entity, enhancing efficiency from 30% to 99.99%.",
      year: "2021",
    },
  ];

  return (
    <>
      <Navbar />

      {/* Hero Section */}
      <section className="bg-gray-100 py-16 text-center">
        <h1 className="text-5xl font-bold mb-4 text-gray-900">ABOUT US</h1>
        <p className="text-lg text-gray-700 px-8 md:px-32">
          We are a premier service provider specializing in IT & Telecom
          consultancy, business development, high-end software solutions,
          satellite connectivity, and fiber deployment. Our expertise spans
          across multiple industries, helping businesses scale and automate
          seamlessly.
        </p>
      </section>

      {/* Services Section */}
      <div className="bg-white py-12 text-center">
        <h2 className="text-4xl font-bold mb-6 text-gray-900">Our Expertise</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 px-6 md:px-16">
          {services.map((item, index) => (
            <Link
              to="/services"
              key={index}
              className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg hover:scale-105 transition-all duration-150"
            >
              {item.icon}
              <h3 className="text-xl font-bold mt-4 text-gray-700">
                {item.title}
              </h3>
              <p className="text-gray-500 mt-2">{item.description}</p>
            </Link>
          ))}
        </div>
      </div>

      {/* Achievements Timeline */}
      <section className="bg-gray-100 py-12">
        <h2 className="text-4xl font-bold text-center text-gray-900 mb-8">
          Star Achievements
        </h2>
        <div className="max-w-4xl mx-auto">
          {achievements.map((achievement, index) => (
            <div key={index} className="flex items-center mb-6">
              <div className="w-20 h-20 bg-blue-500 text-white rounded-full flex items-center justify-center text-xl font-bold">
                {achievement.year}
              </div>
              <div className="ml-6 p-4 bg-white rounded-lg shadow-md w-full">
                <h3 className="text-xl font-bold text-gray-800">
                  {achievement.title}
                </h3>
                <p className="text-gray-600">{achievement.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Client Logos Marquee */}
      <section className="py-12 bg-white">
        <h2 className="text-5xl font-bold text-center mb-9 tracking-[0.15em] uppercase" style={{ color: "#3674B5", fontFamily:"" }}>
          Our Clients
        </h2>
        <hr className="mb-4"/>
        <div className="marquee">
          <div className="marquee-content">
            {/* List of logos */}
            {[
              "https://www.zong.com.pk/assets/images/zong_logo.svg",
              "https://jazz.com.pk/themes/jazz/img/logo-desk-new.png",
              "https://www.ntc-hec.org.pk/assets/img/ntclogo1.JPEG",
              "https://ptcl.com.pk/images/ptcl-logo-plain.svg",
              "https://comsats.net.pk/img/logo3.png",
              "https://www.intelsat.com/wp-content/uploads/2023/02/INTELSAT-logo.png",
              "https://www.nts.org.pk/new/img/nts_logo.png",
              "https://power99.live/wp-content/uploads/2022/01/Power99-copy-2.png",
              "https://super.net.pk/assets/images/logo.png",
              "https://www.neonews.pk/uploads/theme/logo-1666868077.png",
              "https://paksat.com.pk/wp-content/uploads/2024/10/cropped-small-logo.png",
              "https://unet.mn/wp-content/uploads/2024/01/Unet-Solution.png",
              "https://e7.pngegg.com/pngimages/121/146/png-clipart-logo-brand-gazprom-font-product-gazprom-logo-blue-text.png",
              "https://genesisbcs.com/wp-content/uploads/2016/12/cisco2.png",
              "https://www-file.huawei.com/-/media/corporate/images/home/logo/huawei_logo.png",
              "https://www.hec.gov.pk/Style%20Library/HEC/images/main-logo.png",
            ].map((logo, index) => (
              <div key={index} className="client-logo">
                <img alt="client" src={logo} />
              </div>
            ))}

            {/* Duplicate logos for seamless scrolling */}
            {[
              "https://www.zong.com.pk/assets/images/zong_logo.svg",
              "https://jazz.com.pk/themes/jazz/img/logo-desk-new.png",
              "https://www.ntc-hec.org.pk/assets/img/ntclogo1.JPEG",
              "https://ptcl.com.pk/images/ptcl-logo-plain.svg",
              "https://comsats.net.pk/img/logo3.png",
              "https://www.intelsat.com/wp-content/uploads/2023/02/INTELSAT-logo.png",
              "https://www.nts.org.pk/new/img/nts_logo.png",
              "https://power99.live/wp-content/uploads/2022/01/Power99-copy-2.png",
              "https://super.net.pk/assets/images/logo.png",
              "https://www.neonews.pk/uploads/theme/logo-1666868077.png",
              "https://paksat.com.pk/wp-content/uploads/2024/10/cropped-small-logo.png",
              "https://unet.mn/wp-content/uploads/2024/01/Unet-Solution.png",
              "https://e7.pngegg.com/pngimages/121/146/png-clipart-logo-brand-gazprom-font-product-gazprom-logo-blue-text.png",
              "https://genesisbcs.com/wp-content/uploads/2016/12/cisco2.png",
              "https://www-file.huawei.com/-/media/corporate/images/home/logo/huawei_logo.png",
              "https://www.hec.gov.pk/Style%20Library/HEC/images/main-logo.png",
            ].map((logo, index) => (
              <div key={`dup-${index}`} className="client-logo">
                <img alt="client" src={logo} />
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default About;
