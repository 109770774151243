import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../logo.jpg";

function Navbar() {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  function isActive(path) {
    return location.pathname === path ? "text-green-300 scale-95" : "";
  }

  return (
    <nav className="text-white shadow-lg fixed w-full top-0 left-0 z-50 bg-[#3674B5]">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-20">
          {/* Logo Section */}
          <Link
            to="/"
            className="flex items-center font-bold text-2xl space-x-3"
          >
            <img src={Logo} alt="logo" className="h-16 rounded-md" />
            <div className="flex flex-col">
              <span className="text-2xl">SoftBridge</span>
              <span className="text-lg text-[#5DB998]">
                Connecting Businesses
              </span>
            </div>
          </Link>

          {/* Desktop Menu Section */}
          <div className="hidden md:flex space-x-5 font-semibold text-lg content-center">
            <Link
              to="/"
              className={`hover:text-blue-300 hover:scale-105 transition-all duration-150 cursor-pointer ${isActive(
                "/"
              )}`}
            >
              Home
            </Link>
            <Link
              to="/services"
              className={`hover:text-blue-300 hover:scale-105 transition-all duration-150 cursor-pointer ${isActive(
                "/services"
              )}`}
            >
              Services
            </Link>
            <Link
              to="/our-team"
              className={`hover:text-blue-300 hover:scale-105 transition-all duration-150 cursor-pointer ${isActive(
                "/our-team"
              )}`}
            >
              Our Team
            </Link>
            <Link
              to="/about-us"
              className={`hover:text-blue-300 hover:scale-105 transition-all duration-150 cursor-pointer ${isActive(
                "/about-us"
              )}`}
            >
              About Us
            </Link>
            <Link
              to="/contact-us"
              className={`hover:text-blue-300 hover:scale-105 transition-all duration-150 cursor-pointer ${isActive(
                "/contact-us"
              )}`}
            >
              Contact Us
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden text-white focus:outline-none"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>

        {/* Mobile Menu Section */}
        {isMobileMenuOpen && (
          <div className="md:hidden flex flex-col space-y-3 mt-4 pb-4">
            <Link
              to="/"
              className={`hover:text-blue-300 hover:scale-105 transition-all duration-150 cursor-pointer ${isActive(
                "/"
              )}`}
            >
              Home
            </Link>
            <Link
              to="/services"
              className={`hover:text-blue-300 hover:scale-105 transition-all duration-150 cursor-pointer ${isActive(
                "/services"
              )}`}
            >
              Services
            </Link>
            <Link
              to="/our-team"
              className={`hover:text-blue-300 hover:scale-105 transition-all duration-150 cursor-pointer ${isActive(
                "/our-team"
              )}`}
            >
              Our Team
            </Link>
            <Link
              to="/about-us"
              className={`hover:text-blue-300 hover:scale-105 transition-all duration-150 cursor-pointer ${isActive(
                "/about-us"
              )}`}
            >
              About Us
            </Link>
            <Link
              to="/contact-us"
              className={`hover:text-blue-300 hover:scale-105 transition-all duration-150 cursor-pointer ${isActive(
                "/contact-us"
              )}`}
            >
              Contact Us
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
