import { Link } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import {
  FaSatellite,
  FaCloud,
  FaBroadcastTower,
  FaBusinessTime,
  FaNetworkWired,
  FaGlobe,
} from "react-icons/fa";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import "../App.css";

function Dashboard() {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
  
    if (video) {
      const skipIntro = () => {
        if (video.currentTime < 5.2) {
          video.currentTime = 5.2; // Skip first 5.2 seconds
        }
      };
  
      // Add event listeners
      video.addEventListener("loadedmetadata", skipIntro);
      video.addEventListener("timeupdate", skipIntro);
  
      return () => {
        // Clean up event listeners
        video.removeEventListener("loadedmetadata", skipIntro);
        video.removeEventListener("timeupdate", skipIntro);
      };
    }
  }, []);

  const expertise = [
    {
      icon: <FaBusinessTime className="text-6xl text-[#3674B5]" />,
      title: "Business Development",
      description: "Telecom & Corporate Clients",
    },
    {
      icon: <FaCloud className="text-6xl text-[#5DB998]" />,
      title: "High-End Software",
      description: "Advanced software applications",
    },
    {
      icon: <FaBroadcastTower className="text-6xl text-[#3674B5]" />,
      title: "Satellite Bandwidth",
      description: "Video Distribution & Contribution",
    },
    {
      icon: <FaSatellite className="text-6xl text-[#5DB998]" />,
      title: "Satellite IP Connectivity",
      description: "Seamless global communication",
    },
    {
      icon: <FaNetworkWired className="text-6xl text-[#3674B5]" />,
      title: "Optical Fiber Connectivity",
      description: "High-speed, reliable network solutions",
    },
    {
      icon: <FaGlobe className="text-6xl text-[#5DB998]" />,
      title: "Point-to-Point Connectivity",
      description: "Dedicated network solutions",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component is mounted
  }, []);

  useEffect(() => {
    const sections = document.querySelectorAll(".fadeInOnScroll");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the section is visible
    );

    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  return (
    <>
      <Navbar />
      <section className="relative slide">
        <video
          ref={videoRef}
          className="absolute top-0 left-0 w-full h-full object-cover"
          autoPlay
          loop
          muted
          playsInline
          poster="/path/to/fallback-image.jpg" // Add a fallback image
        >
          <source
            src="https://dlmultimedia.esa.int/download/public/videos/2024/10/035/2410_035_BR_003.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </section>
      <section
        className="fadeInOnScroll text-gray-600 body-font"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <div className="container px-5 py-14 mx-auto">
          <div className="flex flex-wrap w-full mb-10 flex-col items-center text-center">
            <h1
              className="text-5xl font-medium title-font"
              style={{ color: "#3674B5" }}
            >
              <b>Key Features</b>
            </h1>
          </div>
          <div className="flex flex-wrap -m-4 text-center">
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border-2 border-green-600 p-6 rounded-lg">
                <h2 className="text-2xl text-green-600 font-medium title-font mb-2">
                  Rapid Deployment
                </h2>
                <p className="leading-relaxed text-base">
                  Satellite services can be rapidly set up and deployed, even in
                  emergency or disaster situations.
                </p>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border-2 border-green-600 p-6 rounded-lg">
                <h2 className="text-2xl text-green-600 font-medium title-font mb-2">
                  Redundancy
                </h2>
                <p className="leading-relaxed text-base">
                  We provide satellite backup and redundant connections to
                  ensure business continuity in case of primary network outages,
                  tailored to customer desires.
                </p>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border-2 border-green-600 p-6 rounded-lg">
                <h2 className="text-2xl text-green-600 font-medium title-font mb-2">
                  Customized Solutions
                </h2>
                <p className="leading-relaxed text-base">
                  We provide customized solutions tailored to customer
                  requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr className="mt-1" />
      <section className="text-gray-600 body-font mb-4">
        <div className="container px-5 py-4 mx-auto">
          <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center mt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="inline-block w-8 h-8 mb-8"
              viewBox="0 0 975.036 975.036"
              style={{ color: "#3674B5" }}
            >
              <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
            </svg>
            <p className="leading-relaxed text-xl text-black">
              SoftBridge, as a value-added reseller of satellite bandwidth,
              provides a full range of planning, operational and optimization
              services to ensure the efficient use of the leased resource.
            </p>
            <span className="inline-block h-1 w-20 rounded bg-green-600 mt-8 mb-6"></span>
          </div>
          <div className="fadeInOnScroll py-10 text-center">
            <h2
              className="text-5xl font-bold text-gray-800 mb-6"
              style={{ color: "#3674B5" }}
            >
              Our Expertise
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-6 md:px-16">
              {expertise.map((item, index) => (
                <Link
                  to="/services"
                  key={index}
                  className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg hover:scale-105 transition-all duration-150 cursor-pointer"
                  aria-label={`Learn more about ${item.title}`}
                >
                  {item.icon}
                  <h3 className="text-xl font-bold mt-4 text-gray-700 hover:text-blue-400">
                    {item.title}
                  </h3>
                  <p className="text-gray-500 mt-2 hover:text-blue-400">
                    {item.description}
                  </p>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Dashboard;
