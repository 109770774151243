import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="text-gray-600 body-font bg-[#16C47F]">
            <div className="container px-4 py-1 flex flex-col md:flex-row items-center justify-between">
                <Link to='' className="flex flex-col items-center md:items-start text-gray-900 pt-2">
                    <span className="text-2xl font-bold text-[#3674B5] ml-6">SoftBridge</span>
                    <span className="text-white text-lg">Connecting Businesses</span>
                </Link>
                <p className="text-lg text-white mt-2 md:mt-0 mb-1 md:mb-0">© 2020 SoftBridge —</p>
                <div className="flex md:mt-0">
                    <a href="https://facebook.com" className="text-white" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                        <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                        </svg>
                    </a>
                    <a href="https://twitter.com" className="ml-3 text-white" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                        <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                        </svg>
                    </a>
                    <a href="https://instagram.com" className="ml-3 text-white" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                        </svg>
                    </a>
                    <a href="https://linkedin.com" className="ml-3 text-white" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                        <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" className="w-5 h-5" viewBox="0 0 24 24">
                            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                            <circle cx="4" cy="4" r="2" stroke="none"></circle>
                        </svg>
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;