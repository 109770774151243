import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from '../src/pages/dashboard';
import Contact from '../src/pages/contact';
import Services from '../src/pages/services';
import About from '../src/pages/about';
import Team from '../src/pages/team';
import './App.css';

function App() {
  return (
    <Router>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/our-team" element={<Team />} />
            <Route path="/services" element={<Services />} />
          </Routes>
    </Router>
  );
}

export default App;